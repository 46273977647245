import { storageNamespace } from "@/config/api"

export default {
  setItem(key: string, value: any) {
    let storage = this.getStorage()
    storage[key] = value
    window.localStorage.setItem(storageNamespace, JSON.stringify(storage))
  },
  getItem(key: string) {
    return this.getStorage()[key]
  },
  clearItem(key: string) {
    let storage = this.getStorage()
    delete storage[key]
    window.localStorage.setItem(storageNamespace, JSON.stringify(storage))
  },
  getStorage() {
    return JSON.parse(window.localStorage.getItem(storageNamespace) || "{}")
  },
  removeStorage() {
    window.localStorage.removeItem(storageNamespace)
  },
  clearAll() {
    window.localStorage.clear()
  }
}