const version = 'v1'
const VITE_ENV = import.meta.env
export const storageNamespace = 'mss-' + VITE_ENV.MODE
export const manage = `/manage/api/${version}`
export const wayline = `/wayline/api/${version}`
export const storage = `/storage/api/${version}`
export const media = `/media/api/${version}`
export const apiConfig = {
  protocol: VITE_ENV.VITE_API_PROTOCOL,
  host: VITE_ENV.VITE_BASE_URL,
  port: VITE_ENV.VITE_BASE_PORT,
  mode: VITE_ENV.MODE,
  AERIAL_PREFIX: '/aerial/',
  AERIAL_VERSION: '2401',
  AERIAL_BUILD: ''
}
export const IS_DEV = VITE_ENV.DEV
export const imageUrlPrefix = window.location.protocol + '//' + window.location.host
export const h5Url = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxbd72801667b46bb4&redirect_uri=https://mss.hdcircles.tech&response_type=code&scope=snsapi_userinfo&state=STATE&forcePopup=true#wechat_redirect'