import { defineStore } from "pinia"
import localStroage from '@/utils/storage'
import { PageName, StorageKey, UserUtilization } from "@/models/enums"
import { router } from "@/router"
import { LoginResponse, UserTokens } from "@/models/login"

const STORE_KEY = 'user'

export const useUserStore = defineStore({
  id: STORE_KEY,
  state: () => ({
    userInfo: {
      userUtilization: UserUtilization.NOT_USED_AND_NOT_YET_READY,
      wechatCode: '',
      userTokens: {
        openid: '',
        access_token: '',
        expires_at: '',
        username: '',
        avatar: '',
        phone_number: ''
      } as UserTokens,
    },
    userLocation: [113.554735, 22.187497] as [number, number],
    center: [113.554735, 22.187497] as [number, number],
    currentRouteName: '' as string | PageName,
    pageNameBeforeLogin: '' as string | PageName,
    routerHistoryList: [] as any[],
  }),
  getters: {
    existingUserToken: (state) => {
      return state.userInfo.userTokens.access_token
    },
    existingUsername: (state) => {
      return state.userInfo.userTokens.username
    },
    existingAvatar: (state) => {
      return state.userInfo.userTokens.avatar
    },
    existingPhoneNumber: (state) => {
      return state.userInfo.userTokens.phone_number
    },
    checkIsLogin: (state) => {
      const userTokens = localStroage.getItem(StorageKey.USER_TOKENS) as UserTokens
      if (state.userInfo.userTokens.access_token || (userTokens && userTokens.access_token)) {
        return true
      } else {
        return false
      }
    },
    maskedPhoneNumber: (state) => {
      const { phone_number } = state.userInfo.userTokens
      let pn = phone_number as any
      if (pn) {
        if (pn.toString().length > 10) {
          return pn.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2")
        } else {
          return pn.replace(/(\d{2})\d{3}(\d{3})/, "$1***$2")
        }
      } else {
        return '********'
      }
    }
  },
  actions: ({
    setUserLocation(userPosition: [number, number]) {
      this.userLocation = userPosition
    },
    setCenter(center: [number, number]) {
      this.center = center
    },
    setUserUtilization(userUtilization: UserUtilization) {
      this.userInfo.userUtilization = userUtilization
    },
    setUserInformation(username: string, avatar: string, phone: string) {
      this.userInfo.userTokens.username = username
      this.userInfo.userTokens.avatar = avatar
      this.userInfo.userTokens.phone_number = phone
    },
    setUserTokens() {
      const userTokens = localStroage.getItem(StorageKey.USER_TOKENS) as UserTokens
      if (userTokens) {
        console.log(`user token exists, set user token...`)
        this.userInfo.userTokens = userTokens
      } else {
        console.log(`check again, user token is not exists. ready go to login page.`)
        this.logout()
        router.push({ name: PageName.LOGIN })
      }
    },
    saveUserTokens(user_tokens: UserTokens) {
      const { access_token, openid, expires_at, username, avatar, phone_number } = user_tokens
      this.userInfo.userTokens.access_token = access_token
      this.userInfo.userTokens.openid = openid
      this.userInfo.userTokens.expires_at = expires_at
    },
    storageUserTokens() {
      localStroage.setItem(StorageKey.USER_TOKENS, this.userInfo.userTokens)
    },
    checkTokenIsExpired(): boolean {
      const userTokens = localStroage.getItem(StorageKey.USER_TOKENS) as UserTokens
      if (userTokens && userTokens.expires_at) {
        console.log(`user token exists, Check if the token has expired...`)
        const { expires_at } = userTokens
        const now = new Date().getTime()
        const exp = new Date(expires_at).getTime()
        return now > exp
      } else {
        console.log(`user token is not exists.`)
        return true
      }
    },
    logout() {
      this.userInfo.wechatCode = ''
      this.userInfo.userTokens.openid = ''
      this.userInfo.userTokens.access_token = ''
      this.userInfo.userTokens.expires_at = ''
      this.userInfo.userTokens.username = ''
      this.userInfo.userTokens.avatar = ''
      this.userInfo.userTokens.phone_number = ''
      localStroage.removeStorage()
    },
  })
}) 