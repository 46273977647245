import { appType } from "@/main"
import { Button, Popup, Picker, Guide, Loading, Image, Message, Toast, PullDownRefresh, ImageViewer, Calendar } from 'tdesign-mobile-vue'

export default function useTDesign(app: appType) {
    app
        .use(Button)
        .use(Popup)
        .use(Picker)
        .use(Guide)
        .use(Loading)
        .use(Image)
        .use(Message)
        .use(Toast)
        .use(PullDownRefresh)
        .use(ImageViewer)
        .use(Calendar)
}