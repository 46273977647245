import { appType } from "@/main"
import { Directive } from 'vue'
import authDirective from "./authDirective"

const directives: Record<string, Directive> = {
    auth: authDirective,
}

export const useCustomDirectives = {
    install: (app: any) => {
        Object.keys(directives).forEach((directiveName) => {
            app.directive(directiveName, directives[directiveName])
        })
    }
}