export interface HttpResponse<T = any> {
  code: Code,
  data: T,
  message: string,
  timestamp: string
}

export enum Code {
  MssErrBodyInvalid = "MSS_ERR_BODY_INVALID",
  MssErrCreated = "MSS_ERR_CREATED",
  MssErrFailed = "MSS_ERR_FAILED",
  MssErrHeadersInvalid = "MSS_ERR_HEADERS_INVALID",
  MssErrInvalid = "MSS_ERR_INVALID",
  MssErrOk = "MSS_ERR_OK",
  MssErrNoResult = "MSS_ERR_NO_RESULT",
  MssErrQsInvalid = "MSS_ERR_QS_INVALID",
  MssErrSuccess = "MSS_ERR_SUCCESS",
}
