import './style.scss'
import 'swiper/css'
import 'swiper/css/pagination'
import '@vuemap/vue-amap/dist/style.css'
import 'vue3-photo-preview/dist/index.css'
import "vue-scroll-picker/lib/style.css"
import 'vant/lib/index.css'
import App from './App.vue'
import { createApp } from 'vue'
import { router } from './router'
import { createPinia } from 'pinia'
import fastclick from 'fastclick'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import VueAMap, { initAMapApiLoader } from '@vuemap/vue-amap'
import VueAMapLoca from '@vuemap/vue-amap-loca'
import vue3PhotoPreview from 'vue3-photo-preview'
import { useInstance } from './root'
import useTDesign from './packages/useTDesign'
import useVant from './packages/useVant'
import useVueScrollPicker from './packages/useVueScrollPicker'
import useGeetest from './packages/useGeetest'
import SvgIcon from '@/components/svg-icon/index.vue'
import { useCustomDirectives } from './directives'
import { useI18n } from './i18n'
export type appType = ReturnType<typeof createApp>
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
const app = createApp(App)
let flag = false

const onWindowResize = () => {
    if (!flag) {
        flag = true
        setTimeout(() => {
            initFontSize()
            flag = false
        }, 100)
    }
}
// 获取屏幕分辨率的宽 / 10
// 1REM = 10px
const initFontSize = () => {
    const viewWidth = document.documentElement.clientWidth
    document.documentElement.style.fontSize = viewWidth / 39 + 'px'
}
// document.documentElement.style.fontSize = window.screen.width / 39 + 'px'
initFontSize()
window.addEventListener('resize', onWindowResize)
// fastclick.FastClick.attach(document.body)
initAMapApiLoader({
    key: 'e03c41c686a0f2edc263d8223c113ef8',
    securityJsCode: '13a3c05ba0206c30606a07c572e9da30', // 新版key需要配合安全密钥使用
    version: '2.0',
    plugins: ['AMap.Driving', 'AMap.Walking', 'AMap.MoveAnimation'],
    Loca: {
        version: '2.0.0'
    } // 如果需要使用loca组件库，需要加载Loca
})
useInstance(app)
useTDesign(app)
useVant(app)
useVueScrollPicker(app)
useGeetest(app)
useI18n(app)
app.component('SvgIcon', SvgIcon)
app.use(router)
app.use(pinia)
app.use(VueAMap)
app.use(vue3PhotoPreview)
app.use(VueAMapLoca)
app.use(useCustomDirectives)
app.mount('#app')