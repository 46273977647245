import { appType } from "@/main"
import { createI18n } from "vue-i18n"
import storage from "@/utils/storage"
import { StorageKey } from "@/models/enums"
import zhCN from './zh-CN'
import zhHK from './zh-HK'

const localLanguage = storage.getItem(StorageKey.LANGUAGE) || navigator.language
const languageList = ['zh-CN', 'zh-HK']
export const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    warnHtmlMessage: false,
    locale: languageList.includes(localLanguage) ? localLanguage : 'zh-CN',
    messages: {
        'zh-CN': zhCN,
        'zh-HK': zhHK
    },
})

export const useI18n = (app: appType) => {
    storage.setItem(StorageKey.LANGUAGE, i18n.global.locale.value)
    app.use(i18n)
}