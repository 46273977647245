import { Directive } from 'vue'
import { useUserStore } from '@/store/user'

const authDirective: Directive = {
    mounted(el, binding, vnode) {
        const userStore = useUserStore()
        const isLoggedIn = userStore.checkIsLogin
        if (!isLoggedIn) {
            el.style.pointerEvents = 'none'
        }
    }
}

export default authDirective