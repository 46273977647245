import { reactive, ref } from "vue"
import { defineStore } from "pinia"
import { BizCode, StorageKey, FlightKey, OrderEvent, OrderStep } from "@/models/enums"
import storage from '@/utils/storage'
import { AcFlightTaskProgressEvent, Ext, FmMissionData, FmMissionResult } from "@/models/device"
import { orderEventResponse } from "@/api/manage/wayline"
import { countdown } from "@/utils/util"

const STORE_KEY = 'flight'

export const useFlightStore = defineStore({
  id: STORE_KEY,
  state: () => ({
    userPosition: [0, 0] as [number, number],
    center: [113.554735, 22.187497] as [number, number],
    currentJourneyStatus: ref<OrderEvent>(OrderEvent.SYNCHRONIZE_FLIGHT_ID_COMPLETED),
    waylineIdList: [] as any[],
    flightIdMap: {} as { [key: number]: string },
    flightEvents: {} as { [flightId: string]: AcFlightTaskProgressEvent },
    journeyTimerObject: ref({
      takeOffCountdown: {
        timer: null as any,
        totalTime: 0,
      },
      enableDrcCountdown: {
        timer: null as any,
        totalTime: 0,
      },
      disableDrcCountdown: {
        timer: null as any,
        totalTime: 45,
      },
      secondWaylineCountdown: {
        timer: null as any,
        totalTime: 0,
      },
    }),
  }),
  getters: {
    currentOrderStep: (state): OrderStep => {
      switch (state.currentJourneyStatus) {
        case OrderEvent.SYNCHRONIZE_FLIGHT_ID_COMPLETED:
        case OrderEvent.PREPARE_COMPLETED:
        case OrderEvent.TAKE_OFF_COUNTDOWN:
        case OrderEvent.TAKE_OFF_COUNTDOWN_ENDS:
          return OrderStep.PREPARE
        case OrderEvent.FIRST_WAYLINE_EXECUTING:
        case OrderEvent.FIRST_WAYLINE_COMPLETED:
        case OrderEvent.ENABLE_DRC_COUNTDOWN:
        case OrderEvent.ENABLE_DRC_COMPLETED:
        case OrderEvent.DISABLE_DRC_COUNTDOWN:
        case OrderEvent.DISABLE_DRC_COUNTDOWN_ENDS:
        case OrderEvent.DISABLE_DRC_COMPLETED:
        case OrderEvent.SECOND_WAYLINE_COUNTDOWN:
        case OrderEvent.SECOND_WAYLINE_EXECUTING:
          return OrderStep.CONTROL
        case OrderEvent.ENABLE_DRC_COUNTDOWN_ENDS:
        case OrderEvent.SECOND_WAYLINE_COUNTDOWN_ENDS:
        case OrderEvent.SECOND_WAYLINE_COMPLETED:
          return OrderStep.END
        default:
          return OrderStep.END
      }
    },
    customVideoStatus: (state) => {
      return (
        state.currentJourneyStatus == OrderEvent.DISABLE_DRC_COUNTDOWN_ENDS ||
        state.currentJourneyStatus == OrderEvent.ENABLE_DRC_COMPLETED ||
        state.currentJourneyStatus == OrderEvent.SECOND_WAYLINE_COMPLETED
      )
    },
  },
  actions: ({
    setUserPosition(userPosition: [number, number]) {
      this.userPosition = userPosition
    },
    setCenter(center: [number, number]) {
      this.center = center
    },
    setFlightIdMap(key: number, flightId: string) {
      if (!this.flightIdMap[key]) {
        this.flightIdMap[key] = flightId
      }
    },
    preSetFlightEvents(flightId: string) {
      if (!this.flightEvents[flightId]) {
        this.flightEvents[flightId] = {} as AcFlightTaskProgressEvent
      }
    },
    setFlightEvents(bizCode: BizCode, info: FmMissionData<AcFlightTaskProgressEvent>) {
      if (bizCode == BizCode.AcFlightTaskProgressEvent) {
        if (info.host.ext.flight_id == this.flightIdMap[FlightKey.FIRST] || info.host.ext.flight_id == this.flightIdMap[FlightKey.SECOND]) {
          this.flightEvents[info.host.ext.flight_id] = info.host
        }
      }
    },
    setJourneyEvent(bizCode: BizCode, info: FmMissionData<FmMissionResult<Ext>>) {
      if (bizCode == BizCode.JourneyEvent) {
        console.info('飞行任务事件接收具体信息:')
        console.info(JSON.stringify(info))
        const { reason, result, service_mission_name, ext } = info.host
        if (result === 0) {
          let name = service_mission_name as OrderEvent
          ext ? this.setCurrentJourneyStatus(name, ext.countdown_ends) : this.setCurrentJourneyStatus(name)
        }
      }
    },
    async setCurrentJourneyStatus(order_event: OrderEvent, countdown_ends?: number) {
      const order_id = storage.getItem(StorageKey.ORDER_ID)
      console.log('事件接收', order_event)
      const res = await orderEventResponse(order_id, order_event)
      const { code, data } = res
      const { order_event: event, reference_number } = data
      // console.log('事件回复', event)
      // if (code === 0) {
      //   if (countdown_ends) {
      //     let totalTime = Math.round((countdown_ends - new Date().getTime()) / 1000)
      //     if (event === OrderEvent.TAKE_OFF_COUNTDOWN) {
      //       this.journeyTimerObject.takeOffCountdown.totalTime =  totalTime
      //       countdown(this.journeyTimerObject.takeOffCountdown)
      //     } else if (event === OrderEvent.ENABLE_DRC_COUNTDOWN) {
      //       this.journeyTimerObject.enableDrcCountdown.totalTime = totalTime
      //       countdown(this.journeyTimerObject.enableDrcCountdown)
      //     } else if (event === OrderEvent.DISABLE_DRC_COUNTDOWN) {
      //       this.journeyTimerObject.disableDrcCountdown.totalTime = totalTime
      //       countdown(this.journeyTimerObject.disableDrcCountdown, true, StorageKey.MQTT_OPTION)
      //       console.log('通过事件获取倒计时', this.journeyTimerObject.disableDrcCountdown)
      //     } else if (event === OrderEvent.SECOND_WAYLINE_COUNTDOWN) {
      //       this.journeyTimerObject.secondWaylineCountdown.totalTime = totalTime
      //       countdown(this.journeyTimerObject.secondWaylineCountdown)
      //     }
      //   }
      //   this.currentJourneyStatus = event
      //   storage.setItem(StorageKey.FLIGHT_STATUS, this.currentJourneyStatus)
      // } 
    },
    clearFlightIdMap() {
      const enumArray = Object.entries(FlightKey)
      for (let [key, value] of enumArray.slice(enumArray.length / 2)) {
        delete this.flightIdMap[+value]
      }
    },
    clearFlightEvents() {
      const flightIdMapArray = Object.entries(this.flightIdMap)
      for (let [key, value] of flightIdMapArray) {
        delete this.flightEvents[this.flightIdMap[+key]]
      }
    },
  })
})