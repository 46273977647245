import { HttpResponse, Code } from '@/models/http'
import axiosRetry from 'axios-retry'
import axios from 'axios'
import { apiConfig, manage } from '@/config/api'
import storage from '@/utils/storage'
import { StorageKey } from '@/models/enums'
import { useUserStore } from '@/store/user'
import { i18n } from '@/i18n'

const { protocol, host, port, AERIAL_PREFIX, AERIAL_VERSION, AERIAL_BUILD } = apiConfig
const ApiServerHost = `${protocol}://${host}:${port}${AERIAL_PREFIX}${AERIAL_VERSION}${AERIAL_BUILD}`
const manageApiWhitelist = [`/user/login/weixin`, '/weixin/jssdk-token', '/spot/tree', '/spot/skypath']

const base = axios.create({
  baseURL: ApiServerHost,
  timeout: 30000,
  headers: {
    'x-mss-os': 'Android, iOS',
    'x-mss-timestamp': new Date().toISOString(),
    'x-mss-lang': i18n.global.locale.value
  }
})
axiosRetry(base, {
  retries: 2,
  retryDelay: (retryCount) => {
    return retryCount * 1000
  },
  onRetry(retryCount, error, requestConfig) {
    requestConfig.__retryCount = retryCount
  },
})

base.interceptors.request.use(config => {
  config.headers['x-mss-timestamp'] = new Date().toISOString()
  config.headers['x-mss-lang'] = i18n.global.locale.value
  if (config.method !== 'get') {
    config.headers['content-type'] = 'application/json'
    config.headers['accept'] = 'application/json'
  }
  if (config.method === 'post') {
    config.data = {
      ...config.data,
      timestamp: new Date().toISOString()
    }
  }
  if (manageApiWhitelist.some(w => { return w == config.url })) {
    config.__url = config.url
    return config
  }
  if (config.__url && manageApiWhitelist.some(w => { return w == config.__url })) {
    return config
  }

  const userStore = useUserStore()
  const usertokens = storage.getItem(StorageKey.USER_TOKENS) || userStore.userInfo.userTokens
  if (!usertokens) {
    return Promise.reject({
      code: 'ERR_UNKNOWN',
      message: 'no token',
      data: '',
      timestamp: new Date().toISOString()
    })
  }
  config.headers!['Authorization'] = 'Bearer ' + usertokens.access_token
  return config
}, async error => {
  console.error('Request ERROR', error)
})

base.interceptors.response.use((response) => {
  if (response) {
    const data = response.data as HttpResponse
    if (data.code === Code.MssErrOk || data.code === Code.MssErrSuccess || data.code === Code.MssErrCreated || data.code === Code.MssErrNoResult) {
      return data.data || data
    } else {
      return Promise.reject(response)
    }
  } else {
    return Promise.reject({
      code: 'ERR_UNKNOWN',
      message: '未知错误',
      data: '',
      timestamp: new Date().toISOString()
    })
  }

}, async error => {
  if (error.response) {
    return Promise.reject({
      code: error.code,
      data: error.response.data,
      message: error.message,
      timestamp: new Date().toISOString()
    })
  } else {
    return Promise.reject(error)
  }
})

export const apiClient = base