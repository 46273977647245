import { wayline, manage, media } from '@/config/api'
import { apiClient } from "../http"
import { HttpResponse } from '@/models/http'
import { OrderEventResponseData, OrderResponseData, WaylineListData, WaylineListRequest } from '@/models/wayline'
import { OrderEvent } from '@/models/enums'

export interface MemoryResponseData {
  create_time: number;
  /**
   * 下载状态(同stage_code, 只是显示为string)
   */
  download_stage: string;
  /**
   * 下载状态码
   */
  download_stage_code: number;
  /**
   * 失败原因.
   */
  failed_reason?: string;
  flight_id: string;
  hotpot?: string;
  /**
   * 图片数量
   */
  image_num: number;
  image_url?: string;
  /**
   * 订单是否已完成
   */
  is_order_completed: boolean;
  journey_name?: string;
  latitude?: number;
  longitude?: number;
  sights?: string;
  /**
   * 视频的压缩状态
   */
  video_compressing_stage: string;
  /**
   * 视频数量
   */
  video_num: number;
  [property: string]: any;
}

export const useWaylineApi = (project_id: string) => {
  const resourcePath = `${wayline}/organizations/projects/${project_id}`

  const getWaylineFiles = async (query: WaylineListRequest): Promise<HttpResponse<WaylineListData>> => {
    const url = `${resourcePath}/waylines`
    return apiClient({
      url,
      method: 'POST',
      data: query
    }).then((response: HttpResponse<WaylineListData>) => {
      return Promise.resolve(response)
    }).catch((error) => {
      return Promise.reject(error)
    })
  }

  return {
    getWaylineFiles
  }
}

/**
 * @description 执行飞至自拍点航线
 * @param order_id 
 */
export const flyToTarget = async (order_id: string): Promise<HttpResponse> => {
  const endpoint = `${wayline}/organizations/projects/users/order/${order_id}/point`
  try {
    const res = await apiClient({
      url: endpoint,
      method: 'POST',
    })
    return await Promise.resolve(res)
  } catch (error) {
    return await Promise.reject(error)
  }
}

/**
 * @description 执行录像航线
 * @param order_id 
 */
export const executeVideoFlight = async (order_id: string): Promise<HttpResponse> => {
  const endpoint = `${wayline}/organizations/projects/users/order/${order_id}/video`
  try {
    const res = await apiClient({
      url: endpoint,
      method: 'POST',
    })
    return await Promise.resolve(res)
  } catch (error) {
    return await Promise.reject(error)
  }
}

/**
 * @description 查询指定订单的订单状态
 * @param order_id 
 */
export const queryOrderStatus = async (order_id: string): Promise<HttpResponse<OrderResponseData>> => {
  const endpoint = `${manage}/organizations/users/order/${order_id}`
  try {
    const res = await apiClient({
      url: endpoint,
      method: 'GET',
    })
    return await Promise.resolve(res)
  } catch (error) {
    return await Promise.reject(error)
  }
}

/**
 * @description 订单事件的应答
 * @param order_id 
 * @param order_event 
 */
export const orderEventResponse = async (order_id: string, order_event: OrderEvent): Promise<HttpResponse<OrderEventResponseData>> => {
  const endpoint = `${manage}/organizations/users/order/event/response`
  const body = {
    reference_number: order_id,
    order_event
  }
  try {
    const res = await apiClient({
      url: endpoint,
      method: 'POST',
      data: body
    })
    return await Promise.resolve(res)
  } catch (error) {
    return await Promise.reject(error)
  }
}

export const getMemoryList = async (project_id: string): Promise<HttpResponse<MemoryResponseData[]>> => {
  const endpoint = `${media}/files/${project_id}/flights`
  try {
    const res = await apiClient({
      url: endpoint,
      method: 'GET',
    })
    return await Promise.resolve(res)
  } catch (error) {
    return await Promise.reject(error)
  }
}